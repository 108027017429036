import { triggeredRefreshToken } from "./api"
import { AuthenticationErrorEnum } from "./type"
import authSignOut from "./authSignOut"
import { getUserInfo } from "./cookies/userInfoStore"

const loginProvider = {
  auth0: "auth0",
  refreshToken: "refreshToken",
}

export type TProvider = keyof typeof loginProvider

let refreshTokenState: "idle" | "fetching" | "success_fetching" = "idle"

type TSignIn = {
  callbackUrl?: string
  baseUrl?: string
  domain?: string
}

export const authSignIn = async (provider: TProvider, props: TSignIn) => {
  const userInfo = getUserInfo()
  const { callbackUrl = "/", baseUrl = "", domain = "" } = props
  const invalidURL = [
    "/auth/signin",
    "/auth/signout",
    "error",
    "callbackUrl",
    "redirect",
  ]
  const invalidCallback = callbackUrl
    ? invalidURL.some((keyword) => callbackUrl?.includes(keyword))
    : true

  switch (provider) {
    case loginProvider.refreshToken:
      if (refreshTokenState !== "fetching") {
        refreshTokenState = "fetching"
        const newToken = await triggeredRefreshToken(baseUrl)
        refreshTokenState = "success_fetching"
        if (!newToken || Object.keys(newToken).length < 1) {
          typeof window !== "undefined" && window?.location?.reload()
        } else if (
          newToken?.error === AuthenticationErrorEnum.ERROR_ACCESS_TOKEN &&
          userInfo?.user?.id
        ) {
          authSignOut({ baseUrl, domain })
        }
      }
      break
    default:
      if (!invalidCallback) {
        const callbackUrlNew = `${baseUrl}${callbackUrl?.replace(/^\/+/, "")}`
        return {
          redirect: true,
          callbackUrl: callbackUrlNew,
        }
      } else {
        return {
          callbackUrl: `${baseUrl}`,
        }
      }
  }

  return undefined
}

export default authSignIn
