import { cx } from "class-variance-authority"
import { type ReactNode, type ChangeEvent } from "react"
import { MapPin, Plus, Search } from "react-feather"
import {
  Button,
  type SingleValue,
  type MultiValue,
  Select,
  Spinner,
  type TSelectOption,
} from "shared-ui"

import EmptyAddress from "./EmptyAddress"
import { type TTempAddress } from "../modal-add-address/type"

type TAddressListProps = {
  addressList: TTempAddress[]
  isLoading?: boolean
  addNewAddressHandler: () => void
  editAddressHandler: (addressIdx: number) => void
  doneFetching?: boolean
  searchValue: string
  searchInputChangeHandler: (e: ChangeEvent<HTMLInputElement>) => void
  customAddBtn?: ReactNode
  setMainAddressHandler?: (
    addressId: string,
    label: string,
    callbackFn?: () => void
  ) => void
  deleteAddressHandler?: (addressId: string) => void
  customSelectAddressHandler?: (address: TTempAddress) => void
  id?: string
  isClickable?: boolean
  selectedBuyerAddressId?: string
  disabledChangeAddress?: boolean
  personaOptions?: Array<TSelectOption>
  personaValue?: TSelectOption | null | undefined
  onPersonaChange?: (
    newValue: SingleValue<TSelectOption> | MultiValue<TSelectOption>
  ) => void
  penyediaRole?: string
}

const AddressList = ({
  addressList,
  isLoading,
  addNewAddressHandler,
  editAddressHandler,
  doneFetching,
  searchValue,
  searchInputChangeHandler,
  customAddBtn,
  setMainAddressHandler,
  deleteAddressHandler,
  customSelectAddressHandler,
  id = "address",
  isClickable = false,
  selectedBuyerAddressId = "",
  disabledChangeAddress = false,
  personaOptions,
  personaValue,
  onPersonaChange,
  penyediaRole,
}: TAddressListProps) => {
  const hasPersona = !!personaValue?.value
  const isAddressEmpty = addressList.length < 1
  const isNoAddressFound =
    (isAddressEmpty && doneFetching) || (!!searchValue && isAddressEmpty)
  const isShowSearchBar = (doneFetching && !isAddressEmpty) || !!searchValue

  const getAddressButton = () => {
    if (customAddBtn) {
      return <div className="w-fit">{customAddBtn}</div>
    } else if (!isAddressEmpty && penyediaRole === undefined) {
      return (
        <div className="w-[22.5%]">
          <Button
            id={`${id}-btn-add`}
            IconLeft={Plus}
            onClick={addNewAddressHandler}
            className="w-full"
          >
            Tambah Alamat Baru
          </Button>
        </div>
      )
    } else {
      return <></>
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex w-full gap-3">
        <div
          className={cx(
            "relative flex flex-grow gap-2",
            customAddBtn || !(!isAddressEmpty && penyediaRole === undefined)
              ? "max-w-full"
              : "max-w-[77.5%]"
          )}
        >
          {hasPersona && (
            <div
              className={cx(
                "flex-1",
                !isShowSearchBar ? "max-w-full" : "max-w-[50%]"
              )}
            >
              <Select
                placeholder="Pilih Akses"
                options={personaOptions}
                defaultValue={personaValue ?? personaOptions?.[0]}
                value={personaValue ?? personaOptions?.[0]}
                onChange={onPersonaChange}
              />
            </div>
          )}
          {isShowSearchBar && (
            <div
              className={cx(
                "rounded-4 border-tertiary50 focus-within:border-tertiary200 flex flex-1 items-center border",
                !hasPersona ? "max-w-full" : "max-w-[50%]"
              )}
            >
              <input
                placeholder="Cari alamat atau nama penerima"
                onChange={searchInputChangeHandler}
                className="rounded-4 text-tertiary500 h-10 w-full flex-grow border-none px-3 py-2 text-sm font-normal outline-none"
              />
              <div className="rounded-r-4 text-tertiary500 flex h-10 items-center bg-[#F6F9FC] p-2 text-xs">
                <Search />
              </div>
            </div>
          )}
        </div>
        {getAddressButton()}
      </div>
      <div className="flex flex-col gap-4">
        {isLoading ? (
          <div className="flex h-full items-center justify-center">
            <Spinner color="primary" size="large" />
          </div>
        ) : isNoAddressFound ? (
          <EmptyAddress
            onClick={addNewAddressHandler}
            isSearch={!!searchValue}
            id={id}
            hasPersona={!!hasPersona}
          />
        ) : (
          addressList?.map((dtData, idx) => {
            const isPinpointMarked = !!dtData.latitude && !!dtData.longitude
            return (
              <div
                key={idx}
                className={cx(
                  "rounded-4 border border-solid p-4 text-sm",
                  isClickable && "cursor-pointer",
                  selectedBuyerAddressId === dtData.id ||
                    (dtData.isMainAddress && selectedBuyerAddressId === "")
                    ? "border-tertiary500 bg-tertiary25"
                    : "border-tertiary50 bg-primary25"
                )}
                onClick={
                  isClickable && customSelectAddressHandler
                    ? () => customSelectAddressHandler(dtData)
                    : undefined
                }
              >
                <div className="mb-2 flex flex-row gap-6 text-sm">
                  <div className="w-23 flex shrink-0 flex-col gap-y-2">
                    <div>
                      <div className="mb-1 flex items-center gap-3">
                        <div>Label Alamat</div>
                        {dtData.isMainAddress && (
                          <div className="rounded-2 bg-tertiary500 text-primary25 px-2 py-1 text-xs">
                            Alamat Utama
                          </div>
                        )}
                      </div>
                      <div className="font-bold">{dtData.label}</div>
                    </div>
                    <div>
                      <div className="mb-1">Nama Penerima</div>
                      <div className="font-bold">{dtData.receiverName}</div>
                    </div>
                    <div>
                      <div className="mb-1">Nomor Telepon</div>
                      <div className="font-bold">{`62${dtData.phoneNumber}`}</div>
                    </div>
                  </div>
                  <div className="flex w-1/2 flex-col">
                    <div>
                      <div className="mb-1">Alamat</div>
                      <div className="font-bold">{dtData.formattedAddress}</div>
                      <div className="font-bold">{dtData.village.name}</div>
                      <div className="font-bold">{dtData.district.name}</div>
                      <div className="font-bold">{dtData.city.name}</div>
                      <div className="font-bold">
                        {dtData.province.name} {dtData.postalCode}
                      </div>
                      <div className="text-tertiary300 mt-2 flex text-xs font-semibold">
                        <MapPin
                          color="#818996"
                          size={20}
                          viewBox="0 -2 36 36"
                        />{" "}
                        {isPinpointMarked ? "Sudah" : "Belum"} ditandai pinpoint
                      </div>
                      {!!dtData.notes && (
                        <div className="mt-1">
                          <p className="text-tertiary500 mb-1">Catatan</p>
                          <p className="text-tertiary500 font-bold">
                            {dtData.notes}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-grow justify-end ">
                    <div
                      className={cx(
                        "flex flex-col gap-y-2",
                        isClickable && "min-w-[150px]"
                      )}
                    >
                      {!dtData.isMainAddress && setMainAddressHandler && (
                        <Button
                          onClick={(e) => {
                            e.stopPropagation()
                            setMainAddressHandler(dtData.id, dtData.label)
                          }}
                          variant="soft"
                          id={`${id}-set-mainAddress`}
                          size={isClickable ? "small" : "medium"}
                          className="whitespace-nowrap"
                        >
                          Jadikan Alamat Utama
                        </Button>
                      )}
                      {!dtData.isSelected &&
                        customSelectAddressHandler &&
                        !isClickable && (
                          <Button
                            id={`select-address-btn-${id}`}
                            onClick={() => customSelectAddressHandler(dtData)}
                            variant="outline1"
                            size="small"
                          >
                            Pilih Alamat
                          </Button>
                        )}
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  {(penyediaRole === undefined ||
                    (!!penyediaRole && penyediaRole === "ADMIN_COMPANY")) &&
                    !(disabledChangeAddress && dtData?.isSelected) && (
                      <Button
                        onClick={(e) => {
                          e.stopPropagation()
                          editAddressHandler(idx)
                        }}
                        variant="transparent"
                        size="medium"
                        id={`${id}-edit-address`}
                        className="btn-transparent !p-0"
                      >
                        Ubah Alamat
                      </Button>
                    )}
                  {!dtData.isMainAddress &&
                    !dtData.isSelected &&
                    deleteAddressHandler && (
                      <>
                        <div className="border-tertiary50 h-[21px] border border-solid" />
                        <Button
                          onClick={(e) => {
                            e.stopPropagation()
                            deleteAddressHandler(dtData.id)
                          }}
                          variant="transparent"
                          size="medium"
                          id={`${id}-delete-address`}
                          className="btn-transparent !p-0"
                        >
                          Hapus
                        </Button>
                      </>
                    )}
                </div>
              </div>
            )
          })
        )}
      </div>
    </div>
  )
}

export default AddressList
