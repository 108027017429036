import { getUserInfoCookie } from "./authentication/cookies/userInfoStore"

const errorKey = ["UNAUTHORIZED", "ERR_EXPIRED_TOKEN"]

/**
 * Function Note:
 * Skip validation unauthorize on auth page
 * Skip validation if userInfo is undefined (meaning the user is not logged in)
 */
const checkUnauthorized = (response: unknown) => {
  const pathname =
    typeof window !== "undefined" ? window?.location?.pathname : ""
  const isAuthPage = pathname.includes("/auth")
  const subscriberHash = JSON.stringify(response).includes("subscriberHash")
  const userInfo = getUserInfoCookie()
  if (!isAuthPage && !subscriberHash && userInfo) {
    const res = JSON.stringify(response)?.toUpperCase()
    return errorKey.some((key) => res.includes(key))
  }
  return false
}

export default checkUnauthorized
