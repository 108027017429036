import { GraphQLClient } from "graphql-request"
import { type RequestMiddleware } from "graphql-request/build/esm/types"
import { type TRequest, graphqlClientMiddleware } from "shared-utils"

export const FEDERATED_TYK_API_URL =
  process.env.NEXT_PUBLIC_CMS_FEDERATED_TYK_URL ||
  process.env.NEXT_PUBLIC_FEDERATED_TYK_URL ||
  "https://gtp-tyk.eproc.dev/graphql/"

const middleware: RequestMiddleware = (request) => {
  const resultMiddleware = graphqlClientMiddleware({
    request: request as TRequest,
    signOut: () => {
      return
    },
  })
  return resultMiddleware
}

export const federatedGqlClient = new GraphQLClient(FEDERATED_TYK_API_URL, {
  requestMiddleware: middleware,
  credentials: "include",
})
