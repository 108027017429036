type TSignOut = {
  domain: string
  baseUrl: string
  toSignOutPage?: boolean
}

export const authSignOut = ({ domain, baseUrl, toSignOutPage }: TSignOut) => {
  return window?.location?.replace(
    `${domain}/logout?returnTo=${baseUrl}${toSignOutPage ? "auth/signout" : ""}`
  )
}

export default authSignOut
