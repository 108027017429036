export const ZENDESK_URL =
  process.env.NEXT_PUBLIC_ZENDESK_URL || "https://bantuan.inaproc.id/hc/id-id"

export const HELPDESK_URL = {
  landing: ZENDESK_URL,
  kebijakan_privasi: `${ZENDESK_URL}/sections/9038144759055-Kebijakan-Privasi`,
  kebijakan_privasi_manajemen_akun: `${ZENDESK_URL}/articles/9038231250703-Kebijakan-Privasi-Manajemen-Akun-Terpusat-SPSE`,
  kebijakan_privasi_e_katalog_v6: `${ZENDESK_URL}/articles/9038212985743-Kebijakan-Privasi-Katalog-Elektronik-Versi-6`,
  syarat_dan_ketentuan: `${ZENDESK_URL}/sections/9038164999567-Syarat-dan-Ketentuan`,
  syarat_dan_ketentuan_manajemen_akun: `${ZENDESK_URL}/articles/9038101775759-Syarat-dan-Ketentuan-Manajemen-Akun-Terpusat-SPSE`,
  syarat_dan_ketentuan_e_katalog_v6: `${ZENDESK_URL}/articles/10157833668111-Syarat-dan-Ketentuan-Katalog-Elektronik-Versi-6-Versi-1-1`,
  panduan_pp_ppk: `${ZENDESK_URL}/sections/9031755994639-Panduan-PP-PPK`,
  panduan_penyedia: `${ZENDESK_URL}/sections/9031756154767-Panduan-Penyedia`,
  panduan_auditor: `${ZENDESK_URL}/sections/8901585482383-Panduan-Auditor`,
  perhitungan_pemotongan_pajak: `${ZENDESK_URL}/articles/7500271235215-Perhitungan-Pemotongan-Pajak-bagi-Penyedia`,
  bantuan: `${ZENDESK_URL}/categories/6791250801935`,
  panduan_pengguna_manajemen_akun_terpusat_spse: `${ZENDESK_URL}/sections/9031757279887-Panduan-Pengguna-Manajemen-Akun-Terpusat-SPSE`,
  tutorial_manajemen_produk: "https://www.youtube.com/watch?v=k3Bj1d4iC-Q",
  kirim_permintaan: `${ZENDESK_URL}/requests/new`,
}
