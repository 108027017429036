import { usePathname, useSearchParams, useRouter } from "next/navigation"
import { useEffect, useState } from "react"
import { Button, Modal } from "shared-ui"
import {
  AuthenticationErrorEnum,
  type TAccessTokenError,
  type TProvider,
  clearErrorSession,
} from "shared-utils/authentication"

import { useDialogAuth } from "./DialogAuthStore"

type TRrrorAuthenticationDesc = {
  [key: string]: {
    title: string
    description: string
  }
}

export const errorAuthenticationDesc: TRrrorAuthenticationDesc = {
  ERROR_TOKEN_INVALID: {
    title: "Akun Telah Keluar",
    description:
      "Anda terdeteksi keluar dari salah satu platform pengadaan. Harap masuk kembali untuk melanjutkan.",
  },
  ERROR_ACCESS_TOKEN: {
    title: "Akun Telah Keluar",
    description:
      "Anda terdeteksi keluar dari salah satu platform pengadaan. Harap masuk kembali untuk melanjutkan.",
  },
  ERROR_TOKEN_REFRESH: {
    title: "Akun Telah Masuk Kembali",
    description:
      "Anda terdeteksi telah masuk kembali. Harap muat ulang halaman untuk melanjutkan.",
  },
}

type TDialogAuth = {
  signIn: (
    provider: TProvider,
    props?: {
      callbackUrl: string
    }
  ) => void
  baseUrl: string
  publicPages?: string[]
  errorSession?: TAccessTokenError
  shouldRefreshPage?: boolean
}

const commonPublicPages = [
  "/auth/signin",
  "/auth/signout",
  "/auth/error",
  "/favicon.ico",
]

const DialogAuth = ({
  signIn,
  baseUrl,
  errorSession,
  publicPages = [],
  shouldRefreshPage,
}: TDialogAuth) => {
  const pathname = usePathname()
  const router = useRouter()
  const searchParams = useSearchParams()
  const callbackUrl = searchParams?.get("callbackUrl")
  const isPageNoAccess = pathname === "/auth/no-access"

  const [isLoading, setIsLoading] = useState(false)

  const {
    isOpen,
    title,
    description,
    onClose,
    openDialogAuth,
    setDialogAuthData,
  } = useDialogAuth()

  const handleToLogin = async () => {
    if (isOpen) {
      setIsLoading(true)
      await clearErrorSession(baseUrl)
      if (isPageNoAccess && !errorSession) {
        router.refresh()
      } else {
        signIn("auth0", { callbackUrl: callbackUrl || pathname || "" })
      }
      onClose()
      setIsLoading(false)
    }
  }

  const checkErrorAction = () => {
    const checkIsPublicPage = [...commonPublicPages, ...publicPages].some(
      (key) => pathname?.includes(key)
    )

    if (!checkIsPublicPage) {
      switch (errorSession) {
        case AuthenticationErrorEnum.ERROR_TOKEN_INVALID:
        case AuthenticationErrorEnum.ERROR_ACCESS_TOKEN:
        case AuthenticationErrorEnum.ERROR_TOKEN_REFRESH:
          setDialogAuthData({
            description:
              errorAuthenticationDesc?.[errorSession]?.description ?? "",
            title: errorAuthenticationDesc?.[errorSession]?.title ?? "",
          })
          openDialogAuth()
          return
        case AuthenticationErrorEnum.ERROR_SESSION_TOKEN_EXPIRED:
          router.refresh()
          return
      }
    }
  }

  const handleRefreshPage = () => {
    const checkIsPublicPage = [...commonPublicPages, ...publicPages].some(
      (key) => pathname?.includes(key)
    )

    if (!checkIsPublicPage) {
      setTimeout(() => {
        router.refresh()
      }, 1500)
    }
  }

  useEffect(() => {
    if (shouldRefreshPage) {
      handleRefreshPage()
    }
  }, [shouldRefreshPage])

  useEffect(() => {
    if (errorSession) {
      checkErrorAction()
    }
  }, [errorSession])

  return (
    <Modal
      open={isOpen && Boolean(title)}
      classNames={{
        content: "w-1/3 !top-1/4",
      }}
    >
      <div className="h-full">
        <h3 className="text-tertiary500 text-lg font-bold">{title}</h3>
        <div className="text-tertiary300 my-4 text-sm">{description}</div>
        <div className="flex">
          <Button
            id="login-button"
            onClick={handleToLogin}
            variant="solid"
            isLoading={isLoading}
            className="w-full"
          >
            Masuk
          </Button>
        </div>
        <div />
      </div>
    </Modal>
  )
}

export default DialogAuth
export { useDialogAuth } from "./DialogAuthStore"
